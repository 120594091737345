export const companyDetails = {
  name: "Evaga",
  fullName: "Evaga Entertainment Pvt Ltd.",
  //   logo: "/logo.png",
  address:
    "No10/12, Prestige Atlanta , 80 Feet Rd, Koramangala , 1 A Block Koramangala , Bengaluru, 560034",
  phone: "123-456-7890",
  email: "info@evaga.com",
  cinNumber: "CIN U82300KA2024PTC187232",
  socialMedia: {
    facebook: "https://www.facebook.com/evaga",
    twitter: "https://www.twitter.com/evaga",
    instagram: "https://www.instagram.com/evaga",
  },
};
